const ssh_root = '/studio-sh';
const post_root = '/post';

/**
 * Webpage Routes
 */
export const routes = {
    kubaparis: `/`,
    about: `/about`,
    imprint: `/imprint`,
    archive: `/archive`,
    archive_page: (page: number) => `/archive/?page=${page}`,
    archive_post: (slug: string) => `/archive/${slug}`,
    calendar: `/calendar`,
    advertise: `/advertise`,
    privacyPolicy: `/privacy-policy`,
    category: (category: string) => `/${category}`,
    post_root: `${post_root}`,
    post: (post: string) => `${post_root}/${post}`,
    submission: (id: string, title: string) => `/submission/${id}`,
    post_test: `${post_root}/test`,
    submit: `/submit`,
    submit_event: `/submit/event`,
    submit_submission: `/submit/exhibition`,
    submit_success: `/submit/success`,
    search_root: `/search`,
    search: (query: string) => `/search?q=${encodeURI(query)}`,
    ssh: `${ssh_root}`,
    ssh_projects: `${ssh_root}/projects`,
    api_countClick: (id: string | number) => `/api/click-count/${id}/data.json`,
    api_loadMorePosts: (offset: number) => `/api/morePosts.json?offset=${offset}`,
};
